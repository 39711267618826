.ant-tabs-nav-wrap{
    justify-content: center;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: rgb(241, 90, 41)!important;
}

.ant-tabs-ink-bar {
    border:  rgb(241, 90, 41)!important;
    background:  rgb(241, 90, 41)!important;
}