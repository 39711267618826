.overlay-div {
    display: flex;
    height: 100%;
    width: 100%;
    z-index: 999;
    background-color: rgba(0,0,0,0.3);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.overlay-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;
    width: 100%;
    height: auto;
}
.overlay-body {
    background-color: white;
    padding: 20px;
    margin: 20px;
    border-radius: 5px;
    width: 100%;
}
.popup-title {
    margin: 10px 0px;
}
.popup-footer {
    display: flex;
    flex-direction: column;
    margin: 10px 0px;
}
.footer-btn-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.confirm-button {
    background-color: #f15a29;
    padding: 5px 10px;
    border-radius: 5px;
    color: white;
}
.body-content {
    
}