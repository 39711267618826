.ant-card-body {
    padding: 10px!important;
}
.ant-form-item .ant-form-item-label >label, .form-label {
    height: auto!important;
    font-size: 18px!important;
}
.ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: flex !important;
    align-self: flex-start !important;
    margin-top: 5px!important;
}
.ant-form-item-label, .form-label {
    word-wrap: break-word;
    font-weight: 700!important;
    margin: 10px 0px !important;
    line-height: 26px !important;
}
.ant-radio-wrapper .ant-radio-checked::after {
    border: 1px solid #f15a29!important;
}
.ant-input, .ant-select-selector {
    border: 0px solid transparent!important;
    font-size: 18px!important;
}
.custom-error-message {
    .ant-message-notice-content {
        background-color: #FF0000 !important;
        color: white !important;
        width: 100% !important;
        padding: 10px !important;
    }
}
.custom-success-message {
    .ant-message-notice-content {
        background-color: #228B22 !important;
        color: white !important;
    }
}
.error-msg {
    color: red !important;
}
.ant-radio-group {
    display: flex!important;
    flex-direction: column!important;
}