.autocomplete-dropdown-container {
	position: "absolute" !important;
	z-index: 1000 !important;
	max-width: 87% !important;
}

.location-search-input,
.location-search-input:focus,
.location-search-input:active {
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08) !important;
	border: honeydew !important;
	display: block !important;
	width: 94% !important;
	padding: 16px !important;
	font-size: 18px !important;
	border-radius: 2px !important;
	outline: none !important;
}

.autocomplete-dropdown-container {
	border-bottom: honeydew !important;
	border-left: honeydew !important;
	border-right: honeydew !important;
	border-top: 1px solid #e6e6e6 !important;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
	position: absolute !important;
	z-index: 1000 !important;
	border-radius: 0 0 2px 2px !important;
}
.ant-modal-header, .ant-modal-body {
	min-width: 100%!important;
}