hr.hr-style {
    color: #e4e1e1 !important;
    border: 0.5px solid #e4e1e1 !important;
    margin-top: 15px !important;
}
.button-wrapper {
    padding: 20px 0px !important;
}
.meeting-event-btn {
    border-radius: 30px !important;
    padding: 10px !important;
    min-width: 70px !important;
    border: 0px solid transparent !important;
    background-color: #7e7e7e !important;
    color: white !important;
}
.start-btn {
    margin-right: 5px !important;
}
.start-active {
    background-color: #228B22 !important;
}
.end-active {
    background-color: #FF0000 !important;
}
.end-btn {
    margin-left: 5px !important;
}
.bold-label {
    font-weight: 800 !important;
    font-size: 16px !important;
}
.meeting-time-wrapper {
    display: flex !important;
    flex-direction: column !important;
}
.form-name {
    font-size: 18px !important;
}
.form-name-row {
    margin: 25px 0px !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}
.custom-error-message {
    .ant-message-notice-content {
        background-color: #FF0000 !important;
        color: white !important;
        width: 100% !important;
        padding: 10px !important;
    }
}
.custom-success-message {
    .ant-message-notice-content {
        background-color: #228B22 !important;
        color: white !important;
    }
}
.ant-message {
    top: 85%!important;
}