.form-head-wrapper {
    padding: 0px 23px !important;;
    margin: 15px 0px !important;;
    display: flex !important;;
    flex-direction: row !important;;
    border-bottom: 1px solid #e4e1e1 !important;;
    h2 {
        padding: 0px!important;
    }
}
.header-title {
    padding: 0px!important;
    font-weight: 500!important;
    margin-left: 10px;
    font-size: 22px!important;
}
.header-left-icon {
    margin: 10px 10px auto -5px !important;;
}
.head-logo {
    height: 30px !important;;
}
.sticky-head-wrapper {
    position: sticky !important;;
    top: 0 !important;;
    z-index: 99 !important;;
    background-color: white !important;;
}